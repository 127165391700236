import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import YoutubeVideo from "../components/youtube"

const projectsPage = () => (
  <Layout pageName="Projects">
    <SEOComponent  title="Projects" />
    <div className="columns m-0 pt-4 is-multiline">

    <div className="column is-half">
      <div className="content">
        <h2>Flem from Super New City: The Agency</h2>
        <YoutubeVideo videoSrcURL="https://www.youtube.com/embed/Ajlo1wxIdM0" videoTitle="Flem character from Super New City" />
        <h3>Enemy for animated short and video game.</h3>
        <ul>
          <li>Face blendshapes</li>
          <li>IK (Inverse Kinematics) legs and arms</li>
          <li>FK (Forward Kinematics) legs and arms</li>
          <li>IK to FK Blending, animatable</li>
          <li>Head, Arm Local Space switching</li>
        </ul>
      </div>
    </div>

    <div className="column is-half">
      <div className="content">
        <h2>Witch #2 from Witches Be Crazy</h2>
        <YoutubeVideo videoSrcURL="https://www.youtube.com/embed/UMO_GE1uCyg" videoTitle="Witch #2 from Witches be Crazy" />
        <h3>Enemy for video game.</h3>
        <ul>
          <li>Face blendshapes</li>
          <li>IK (Inverse Kinematics) legs and arms</li>
          <li>FK (Forward Kinematics) legs and arms</li>
          <li>IK to FK Blending, animatable</li>
          <li>Head, Arm Local Space switching</li>
        </ul>
      </div>
    </div>

    <div className="column is-half">
      <div className="content">
        <h2>Self Portrait</h2>
        <YoutubeVideo videoSrcURL="https://www.youtube.com/embed/Cx-rA7bFuIY" videoTitle="POB3D Self Portrait" />
        <h3>This is a self portrait in 3D cartoon style.</h3>
        <ul>
          <li>Custom 3-tone toon shader, comprised of 3 ramp textures piped into a Ramp Shader node, enabling each of 9 toon shades to be animated and changed on the fly</li>
          <li>IK (Inverse Kinematics) legs and arms</li>
          <li>Stretchy IK arms and spine, for added flexibility in animation</li>
        </ul>
      </div>
    </div>

    </div>

  </Layout>
)

export default projectsPage